import React from 'react';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

export default ({ location }) => (
  <Layout displayFooter location={location}>
    <Helmet title="Histoire" />
    <section className="px-10 py-10" style={{ minHeight: 'calc(100vh - 80px - 415px' }}>
      <h1>Notre histoire</h1>
      <p>
        Irure eiusmod esse irure magna laboris fugiat eiusmod sint esse adipisicing velit consectetur ex tempor.
        Voluptate enim Lorem proident adipisicing dolor dolor dolore. Voluptate laboris sunt et nulla ut ea. Tempor
        officia incididunt pariatur dolore commodo aliqua. Labore dolor ut cillum in do incididunt aute anim duis
        deserunt. Ut in aute exercitation do proident ad et ipsum.
      </p>
      <p>
        Cupidatat sint ea commodo fugiat esse minim. Consectetur et fugiat voluptate occaecat magna ullamco id nisi
        eiusmod mollit sint laboris voluptate anim. Ut anim officia enim dolor ea labore dolore consectetur magna mollit
        id proident aliquip. Enim exercitation laborum et minim aute culpa veniam qui do mollit et nulla culpa et.
        Proident quis irure aliquip enim sit et mollit est dolor voluptate. Dolor adipisicing eu officia proident ad
        elit laborum dolore labore dolore nulla esse. Et in exercitation amet eiusmod mollit aliqua labore commodo
        commodo enim sit.
      </p>
      <p>
        Et culpa aliquip labore laborum enim irure ea minim nulla quis esse. Et voluptate incididunt tempor incididunt
        velit in magna sit officia elit consequat cillum. Labore adipisicing commodo nulla exercitation dolor qui eu
        reprehenderit amet nulla. Laborum commodo officia velit quis excepteur eu incididunt dolor duis. Eu id minim in
        qui elit aliqua. Amet cillum reprehenderit quis irure dolor anim sint enim et reprehenderit ea dolore sint
        proident. Mollit ex do aute sunt culpa sint incididunt ipsum irure mollit id aute.
      </p>
    </section>
  </Layout>
);
